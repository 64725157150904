<template>
	<div class="w-full">
		<WwModal
			:modal-id="EDIT_REVIEW_MODAL"
			title="Edit Review"
			max-width="w-full md:max-w-lg"
			:transition-direction="!isMobile ? 'down' : 'bottom'"
			@ok="handleSubmit"
		>
			<template #body>
				<form
					ref="reviewEditForm"
					v-require-login="{ callback: handleSubmit }"
					class="w-full mx-auto"
				>
					<textarea
						v-model.trim="review"
						class="w-full mb-2 placeholder-gray-500 bg-gray-200 border-none rounded focus:ring focus:ring-green-300"
						required
					/>
					<div
						v-if="isMounted"
						class="flex flex-wrap w-full mx-auto mb-2 rating-row"
					>
						<div
							v-for="(type, index) in ratingTypes"
							:key="index"
							class="items-center w-1/2 py-1 lg:w-1/3"
						>
							<div class="label">
								{{ type.name }}
							</div>
							<StarRating
								v-if="isMounted"
								v-model="ratings[type.key]"
								:star-size="20"
								:padding="6"
								class="mr-4"
								:show-rating="false"
							/>
						</div>
					</div>
				</form>
			</template>
		</WwModal>
	</div>
</template>

<script async>
import { mapGetters, mapMutations } from 'vuex'

import WwModal from '@/components/UI/WwModal.vue'
import { EDIT_REVIEW_MODAL } from '@/constants/modal/names.js'

export default {
	components: {
		StarRating: () => import('vue-star-rating'),
		WwModal
	},
	props: {
		reviewEdit: {
			type: Object,
			required: true
		},
		business: {
			type: Object,
			required: true
		},
		reviewRatings: {
			type: Object,
			required: true
		},
		ratingTypes: {
			type: Array,
			required: true
		}
	},
	emits: [ 'update-review' ],
	data() {
		return {
			review: this.reviewEdit.review,
			review_id: this.reviewEdit.reviewid,
			ratings: {},
			showReviewForm: true,
			showSuccessMessage: false,
			isMounted: false,
			EDIT_REVIEW_MODAL
		}
	},
	computed: {
		...mapGetters([ 'isMobile' ]),
		formData() {
			return {
				listing_id: this.business.listing_id,
				review: this.review,
				ratings: this.ratings,
				id: this.review_id
			}
		},
		deliveryOnly() {
			if (
				this.business.delivery_only == 0 ||
        this.business.delivery_only === null
			) {
				return false
			} else {
				return true
			}
		}
	},
	watch: {
		reviewEdit() {
			this.review = this.reviewEdit.review
			this.review_id = this.reviewEdit.reviewid
		},
		reviewRatings() {
			this.ratings = this.reviewRatings
		}
	},
	mounted() {
		this.review = this.reviewEdit.review
		this.review_id = this.reviewEdit.reviewid
		if (this.reviewRatings) {
			this.ratings = this.reviewRatings
		}
		this.isMounted = true
	},
	methods: {
		...mapMutations('modal', [ 'closeModal' ]),
		handleClose(event) {
			event.stopPropagation()
			this.closeModal()
		},
		async handleSubmit() {
			this.$emit('update-review', this.reviewEdit.id, this.review, this.ratings)
			this.closeModal()
		}
	}
}
</script>
